<template>
  <div>
    <div class="head-bg">
      <!-- <van-image width="100vw" :src="img1" /> -->
      <div class="ruls" @click="showrule = true">规则</div>
      <van-image width="100vw" :src="detail.merge_image_bg" />
    </div>
    <div class="meng"></div>
    <div class="content">
      <div class="content-item">
        <div class="title">
          <div class="name">
            叶辰/姬凝霜
            <img :src="levelsChange(3)" alt="" />
          </div>
          <div class="num">限量合成230份</div>
        </div>
        <div class="cl-box">
          <div class="title"><img src="./img/cailiao1.png" alt="" /></div>
          <div class="hero hero1" v-if="detail.items && detail.items.length">
            <div class="hero-item w33">
              <div class="box1">
                <img :src="detail.items[0].image_icon" alt="" />
                <div class="num">{{ detail.items[0].user_num }}</div>
                <div
                  class="item-meng"
                  @click="heroFn"
                  v-if="detail.items[0].user_num < detail.items[0].number"
                ></div>
                <div class="neednum">
                  需<span>{{ detail.items[0].number }}</span
                  >个
                </div>
              </div>
            </div>
            <div class="hero-item w33">
              <div class="box1">
                <img :src="detail.items[1].image_icon" alt="" />
                <div class="num">{{ detail.items[1].user_num }}</div>
                <div
                  class="item-meng"
                  @click="heroFn"
                  v-if="detail.items[1].user_num < detail.items[1].number"
                ></div>
                <div class="neednum">
                  需<span>{{ detail.items[1].number }}</span
                  >个
                </div>
              </div>
              <div class="box1 martop14">
                <img :src="detail.items[2].image_icon" alt="" />
                <div class="num">{{ detail.items[2].user_num }}</div>
                <div
                  class="item-meng"
                  @click="heroFn"
                  v-if="detail.items[2].user_num < detail.items[2].number"
                ></div>
                <div class="neednum">
                  需<span>{{ detail.items[2].number }}</span
                  >个
                </div>
              </div>
            </div>
            <div class="hero-item w33">
              <div class="box1">
                <img :src="detail.items[3].image_icon" alt="" />
                <div class="num">{{ detail.items[3].user_num }}</div>
                <div
                  class="item-meng"
                  @click="heroFn"
                  v-if="detail.items[3].user_num < detail.items[3].number"
                ></div>
                <div class="neednum">
                  需<span>{{ detail.items[3].number }}</span
                  >个
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add">
          <van-icon name="plus" />
        </div>
        <div class="cl-box" style="margin-top: 0px">
          <div class="title"><img src="./img/addcailiao.png" alt="" /></div>
          <!-- style="justify-content: space-around; padding: 0 70px" -->
          <div class="add-hero">
            <div class="add-left hero">
              <div class="hero-item w33" v-if="detail.items && detail.items.length">
                <div class="box1 add-box-item">
                  <img :src="detail.items[4].image_icon" alt="" />
                  <div class="num">{{ detail.items[4].user_num }}</div>
                  <div
                    class="item-meng"
                    @click="heroFn"
                    v-if="detail.items[4].user_num < detail.items[4].number"
                  ></div>
                  <div class="stepper">
                    <div :class="addnum == 10 || addnum < 10 ? 'gre' : ''" @click="addnumFn(1)">
                      <van-icon name="minus" />
                    </div>
                    <div class="num-box">{{ addnum }}</div>
                    <div :class="addnum == max ? 'gre' : ''" @click="addnumFn(2)">
                      <van-icon name="plus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-right">
              <div class="line"></div>
              <div class="">
                <div class="add-r-item">
                  <div class="title">叶辰/姬凝霜合成概率：</div>
                  <div class="num">
                    75% + <span>{{ shinum }}%</span>
                  </div>
                </div>
                <div class="add-r-item">
                  <div class="title">东荒女帝合成概率：</div>
                  <div class="num">
                    0.7% + <span>{{ nvnum / 10 }}%</span>
                  </div>
                </div>
                <div class="add-r-item">
                  <div class="title">5000积分合成概率：</div>
                  <div class="num">
                    24.3% - <span class="red">{{ pointnum / 10 }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <!-- <div class="hc-btn">   :priority_start_time="detail.priority_start_time"立即合成</div> -->
          <cpdBtn
            :time="!detail.is_user_priority ? detail.start_time : detail.priority_start_time"
            :end_time="detail.end_time"
            :hasAll="detail.hasAll"
            @finistTime="finistTime"
            :is_merge="detail.is_merge"
            @compoundHreo="compoundHreo"
            :server_time="server_time"
          />
        </div>
        <!--  -->
        <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>传说级藏品</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div>
      </div>
      <div class="content-item">
        <div class="sub-title">
          <img class="title-img" src="./img/longtitle.png" alt="" />
        </div>
        <div class="sub-content">
          史诗级藏品<span>「叶辰」</span>、<span>「姬凝霜」</span>具备修炼资格，定时修炼可<span>免费获得积分</span>（积分可参与抽奖、兑换商品等各种限时福利活动）。
        </div>
      </div>
      <div class="content-item">
        <div class="sub-title">
          <img src="./img/title.png" alt="" />
        </div>
        <div class="sub-content t-c">
          <!-- {{ detail.merge_item_description }} -->
          合成时最高有1.7%的概率合成传说级藏品<span>「东荒女帝」</span>。
        </div>
      </div>
    </div>
    <!-- 规则说明 -->
    <van-action-sheet v-model:show="showrule" :round="false" style="background-color: #1c172a">
      <div class="header">
        <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="showrule = false" />
        <img src="@/imgs/comm/pic_tc_title.png" class="img-title" />
        <span>合成须知</span>
      </div>
      <div class="block"></div>
      <div class="content-van">
        <div class="nei">
          <div class="nei-title">合成时间：</div>
          2022年7月22日 17:00 - 2022年7月23日
          16:59持有传说级藏品的用户，可享有优先合成权益。7月22日14:00快照，7月22日15:00开启优先合成。每持有一份传说级藏品，可享有一次优先合成权益，权益可累计叠加。
          <br /><br />
          <div class="nei-title">合成材料：</div>

          <span>楚萱儿*1 楚灵儿*1 气武境*2 灵武境*2 赤霄剑*10</span><br /><br />

          <div class="nei-title">合成规则：</div>

          1、本期合成限时开放，限量230份史诗级藏品，先到先得，正式开启后，每人不限合成次数；<br /><br />
          2、合成时有75%的初始概率获得史诗级的「姬凝霜」或「叶辰」
          ，0.7%的概率获得传说级的「东荒女帝」，24.3%的概率获得5000积分；<br /><br />
          3、合成时每追加10把赤霄剑，获得史诗级藏品的概率可提高1%，获得传说级藏品「东荒女帝」的概率可提高0.1%，获得5000积分的概率降低1.1%，每次合成最高可追加100把赤霄剑；<br /><br />
          4、按7月19日16：30用户持有的传说级藏品数量，享有对应的优先合成次数；<br /><br />
          5、合成成功后，所需的材料将被销毁。
          <br />
          <br />
          <p class="t-c">最终解释权归看漫所有</p>
        </div>
      </div>
      <!-- <div class="block"></div> -->
    </van-action-sheet>
    <PageLoading :show="loading" />
    <compoundPop
      :compoundPopShow="compoundPopShow"
      :effect_img="effect_img"
      :addnum="addnum"
      @close="(compoundPopShow = false), finistTime()"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getmergeitems } from '@/service/home';
import { getstuff } from '@/service/compound';
import cpdBtn from '@/components/CpdBtn';
import compoundPop from './components/compoundPop.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { Toast } from 'vant';
import { shareHelper } from '@/helpers';
import { domUtil } from '@/utils';
export default {
  components: {
    cpdBtn,
    compoundPop,
    PageLoading
  },
  setup() {
    const state = reactive({
      showrule: false,
      detail: {},
      compoundPopShow: false,
      token_id: 0, // 选择的材料中的id
      effect_img: '',
      loading: true,
      server_time: 0,
      addnum: 10, //材料2 选择数量
      max: 0, //材料2最大数量
      shinum: 0,
      nvnum: 0,
      pointnum: 0
    });

    onMounted(async () => {
      finistTime();
      const shareConf = {
        title: '史诗级藏品已开放合成',
        content: '《仙武帝尊》史诗级藏品已开启合成，速来~',
        image: '',
        link: location.href // app分享链接
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);
    });
    const heroFn = async () => {
      Toast('该材料不足，快去购买材料吧～');
    };
    const finistTime = async () => {
      const { data, server_time } = await getstuff();
      state.detail = data;
      state.server_time = server_time;
      state.effect_img = data.effect_img;

      let merge_config_items = data.items,
        user_merge_config_items = data.user_stuff_list,
        arr = [],
        subitem,
        hasAll = true; // 材料是否充足;
      // 循环得出用户拥有数

      merge_config_items.map((item, index) => {
        if (user_merge_config_items.length == 0) {
          hasAll = false;
          console.log(11111);
          arr.push({ ...item, user_num: 0 });
        } else {
          for (let i = 0; i < user_merge_config_items.length; i++) {
            subitem = user_merge_config_items[i];

            if (item.token_id == subitem.token_id) {
              arr.push({ ...item, user_num: subitem.number });
              // 判断材料2 选择第一个
              // 材料1不足
              if (subitem.number < item.number) {
                hasAll = false;
              }

              break;
            }
            if (user_merge_config_items.length - 1 == i && item.token_id != subitem.token_id) {
              arr.push({ ...item, user_num: 0 });
              // 材料不足
              hasAll = false;

              break;
            }
          }
        }
      });

      state.detail.items = arr;
      state.max =
        state.detail.items[4].user_num < 110
          ? Math.floor(state.detail.items[4].user_num / 10) * 10
          : 110;
      console.log(hasAll, arr, '....');
      // state.addnum = state.max;
      state.detail = { ...state.detail, hasAll };
      state.loading = false;
    };
    //数量增加
    const addnumFn = type => {
      //  shinum: 0,
      // nvnum: 0,
      // pointnum: 0
      if (type == 1) {
        // 减
        if (state.addnum < 10 || state.addnum == 10) {
          Toast('合成最少需要十份');
        } else {
          state.addnum -= 10;
          state.shinum = (state.addnum - 10) / 10;
          state.nvnum = (state.addnum - 10) / 10;
          state.pointnum = state.shinum * 10 + state.nvnum;
        }
      } else {
        // +
        //判断可添加的数量

        if (state.addnum < state.max) {
          state.addnum += 10;
          state.shinum = (state.addnum - 10) / 10;
          state.nvnum = (state.addnum - 10) / 10;
          state.pointnum = state.shinum * 10 + state.nvnum;
        } else {
          let str = state.max < 110 ? '每次只能添加十份哦' : '已添加最大数量';
          Toast(str);
        }
      }
    };
    // 合成按钮
    const compoundHreo = () => {
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const userNum = id => {
      return 1;
    };
    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      userNum,
      finistTime,
      compoundHreo,
      timeChange,
      addnumFn,
      heroFn,
      diffTime,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.stepper {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-top: 10px;
  align-items: center;
  .gre {
    color: #453d5b;
  }
  .num-box {
    width: 50px;
    height: 26px;
    background: #453d5b;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #a297bf;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 7px;
  }
}
.add-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-hero {
  margin-top: 26px;
  display: flex;
  align-items: center;

  .add-left {
    width: 50%;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .add-right {
    display: flex;
    align-items: center;
    .line {
      margin-right: 20px;
      width: 0px;
      height: 77px;

      border: 1px solid #453d5b;
    }
    .add-r-item {
      margin-bottom: 10px;
      .title {
        font-size: 12px;
        font-weight: 400;
      }
      .num {
        font-size: 16px;
        font-weight: 500;
        span {
          color: #3cdbe8;
        }
        .red {
          color: #e83c3c;
        }
      }
    }
  }
}
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content-van {
    background-color: $bc;
    color: $fc;
    font-size: 14px;

    .nei {
      margin-left: 16px;
      margin-right: 16px;

      color: rgba(255, 255, 255, 0.7);
      .nei-title {
        color: rgba(255, 255, 255, 1);
      }
      span {
        color: #3cdbe8;
      }
      p {
        margin: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.brigt-content {
  margin-bottom: 24px;
}
.head-bg {
  position: relative;
  .ruls {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: 9;
    width: 64px;
    height: 24px;
    background: #58dedd;
    font-size: 12px;
    color: #000000;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 500;
    // justify-content: center;
    align-items: center;
  }
}
.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../../imgs/comm/Slice9.png');
  margin-bottom: 24px;
}
.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
.hc-btn {
  background: url('../../imgs/comm/hcbtn.png') no-repeat;
  background-size: 100% 100%;
  width: 164px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #20322c;
}
.meng {
  width: 100vw;
  height: 160px;
  position: relative;
  top: -160px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
}
.content {
  padding: 0 16px 86px;
  position: relative;
  top: -247px;
}
.add {
  font-size: 24px;
  text-align: center;
  // margin: 4px 0;
}
.content-item2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    width: 100%;
    margin-top: 6px;
    img {
      width: 237px;
      margin: 0 auto;
    }
  }
}
.content-item {
  background: #2f293f;
  padding: 11px;
  margin-bottom: 14px;
  position: relative;
  .sub-title {
    // position: absolute;
    // top: -16px;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    font-size: 0px;
    .title-img {
      width: 100%;
      height: 50px;
    }
    img {
      width: 181px;
      height: 50px;
    }
  }
  .sub-content {
    // margin-top: 34px;
    font-size: 12px;

    color: rgba(255, 255, 255, 0.7);
    span {
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
    }
    p {
      margin: 2px 0;
    }
    div {
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    .name {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    img {
      width: 50px;
      margin-left: 5px;
    }
    .num {
      background: #3cdbe8;
      color: #2f293f;
      width: 85px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      span {
        font-weight: 500;
      }
    }
  }
  .cl-box {
    background: #1c172a;
    padding: 0 5px 10px 5px;
    margin-top: 18px;
    .title {
      text-align: center;
      font-size: 0px;
      padding: 5px 0;
      img {
        width: 237px;

        margin: 0 auto;
      }
    }
    .hero-wrapper {
      display: flex;
      justify-content: center;
    }
    .hero1 {
      padding: 0 27px;
    }
    .hero {
      display: flex;
      flex-wrap: wrap;
      font-size: 0px;
      align-items: center;
      justify-content: space-between;
      //justify-content: space-between;
      img {
        width: 70px;
      }
      .mr10 {
        margin-right: 10px;
      }
      .choose {
        border: 1px solid #5fdfd3;
        border-radius: 4px;
        background-color: #2f293f;
      }
      .nochoose {
        border: 1px solid #1c172a;
        border-radius: 4px;
      }
      // .w33 {
      //   width: 33%;
      // }
      .hero-item {
        // position: relative;

        .box1 {
          // margin-right: 10px;
          // &:nth-child(3n + 4) {
          //   margin-right: 0px;
          // }
          position: relative;
          margin: 0 auto;
          text-align: center;
        }
        .martop14 {
          margin-top: 14px;
        }
        .num {
          position: absolute;
          right: 9px;
          top: 9px;
          background: #2f293f;
          font-size: 12px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          z-index: 2;
        }
        .neednum {
          font-size: 12px;
          text-align: center;
          span {
            color: $primary;
          }
        }
        .item-meng {
          width: 69px;
          height: 73px;
          background: #2f293f;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
